import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import VueI18n from "vue-i18n";
import axios from 'axios';
import VueAxios from 'vue-axios'
import Meta from "vue-meta";
import Vue2TouchEvents from "vue2-touch-events";
import vueSocialAuth from "vue-social-auth";
import titleMixin from "./utils/titleMixin";
import VueTelInput from "vue-tel-input";
import 'vue-tel-input/dist/vue-tel-input.css';

import Routes from "./routes";
import Store from "./store";

import messages from "./i18n";

Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(Meta);
Vue.use(Vue2TouchEvents);
Vue.use(VueAxios, axios)
Vue.use(vueSocialAuth, {
  providers: {
    github: {
      clientId: '',
      redirectUri: '/auth/github/callback' // Your client app URL
    },
    google: {
      clientId: '',
      redirectUri: '/auth/github/callback' // Your client app URL
    },
    facebook: {
      clientId: '',
      redirectUri: '/auth/github/callback' // Your client app URL
    },
    twitter: {
      clientId: '',
      redirectUri: '/auth/github/callback' // Your client app URL
    },
  }
});

Vue.mixin(titleMixin);
Vue.use(VueTelInput);

const i18n = new VueI18n({
  locale: "fr",
  fallbackLocale: "en-gb",
  messages,
});

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.config.ignoredElements = [/^ion-/, /asside/];

Vue.filter("truncate", function(value, length=100, suffix="...") {
  if (!value) return "";
  if (value.length < length) return value;
  return value.substring(0, length) + suffix;
});

const router = new VueRouter({
  routes: Routes,
  mode: "history",
  base: "/",
  // Modifier les valeurs par defaut de router-class-active / exact
//   linkActiveClass: "active",
//   linkExactActiveClass: "exact-active",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (Store.getters.isLoggedIn /*&& Store.getters.user.username*/) {
      next();
      return;
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

new Vue({
  render: (h) => h(App),
  router: router,
  store: Store,
  i18n: i18n,
}).$mount("#app");
