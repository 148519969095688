<template src="../../templates/settings.html"></template>

<script>
import { mapGetters } from "vuex";
import MenuLeft from "../commons/MenuLeft.vue";
import AsideRight from "../commons/AsideRight.vue";

export default {
  components: {
    MenuLeft,
    AsideRight,
  },
  name: "Settings",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    handleBurger() {
      const burger = document.querySelector(".burger-block");
      const navigation = document.querySelector(".navigationAuth");
      burger.addEventListener("click", () => {
        navigation.classList.toggle("active");
      });
    },
  },
  created() {},
};
</script>

<style scoped>
@import "../../assets/css/index.css";
</style>
