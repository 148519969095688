// english version

export default {
    // whole app
    appName: "Winexam",

    // Home Component
    homeVisionLinkText: "Our Vision",
    homeAboutLinkText: "About Us",
    homeContactLinkText: "Contact",
    homeBtnLogin: "Login",
    homeBtnRegister: "Register",
    homeLangFrench: "Francais",
    homeLangEnglish: "English",
    homeLangGerman: "Deutsch",
    //
    homeHomeFirstText: "helps you learn online at your own speed",
    homeHomeSecondText: "Create questions, review and share them with your friends.",
    homeHomeThirdText: "Get started",
    //
    homeVisionFirstText: "We accompany you on your journey",
    homeVisionSecondText: "Winexam is an application designed to help scholars, students and anyone who wants to prepare for their exams.",
    homeVisionThirdText: "Learn now",
    //
    homeVision2FirstText: "We give you the opportunity to work together",
    homeVision2SecondText: "Winexam's social function allows you to share your surveys so that others can add questions and/or just use them to prepare themselves.",
    homeVision2ThirdText: "Go ahead",
    //
    homeAboutFirstText: "About us",
    homeAboutSecondText: "We are two motivated developers who had the idea to create a web application that will allow students and anyone who wants to learn to do so easily.",
    //
    homeFeedbackFirstText: "Give us your experience",
    homeFeedbackSecondText: "For the improvement of the application we give you the opportunity to rate us and send us your feedback, please do not hesitate!",
    homeFeedbackThirdText: "Send feedback",
    //
    homeAlexName: "Alex Mboutchouang",
    homeAlexDescription: "Founder of the project and FullStack Developer of the application",
    homeLnName: "Leonel Ngoya",
    homeLnDescription: "FrontEnd developer in charge of the user interface",
    //
    homeContactFirstText: "Contact us",
    homeContactEmailplaceholdertText: "Email",
    homeContactMessagePlaceholderText: "Leave a message ...",
    homeContactSendBtnText: "Send your message",
    //
    homeCopyright: "Winexam all rights reserved 2022",

    // Authentification Component
    LoginNewTitle: "Sign Up",
    loginNewText: "Do you already have an account ?",
    loginNewChangeText: " Sign in",
    loginNewBtnText: "Save",
    LoginLogTitle: "Sign in",
    loginLogText: "You don't have an account ?",
    loginLogChangeText: " Create one",
    loginLogBtnText: "Login",
    loginFormId: "Username",
    loginFormEmail: "Email adress",
    loginFormName: "Name",
    loginFormFirstName: "First name",
    loginFormLevel: "Level",
    LoginFormPassword: "Password",
    LoginFormPhone: "Phone number",

    // Footer Component
    footerConctactText: "Contacts",
    footerLinksText: "useful links",
    footerLink1Text: "Privacy policy",
    footerLink2Text: "Terms of use",
    footerLink3Text: "FAQs",

    // Footer Component
    menuLeftHomeText: "Home",
    menuLeftDashboardText: "Dashboard",
    menuLeftMessageText: "Messages",
    menuLeftNotificationText: "Notifications",
    menuLeftEvolutionText: "Evolution",
    menuLeftSettingsText: "Settings",
    menuLeftCommunityText: "Community",
    menuLeftLogoutText: "Logout",
}