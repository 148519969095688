<template>
	<nav class="navigation">
    <div class="cards">
      <div class="navigation-left">
        <router-link :to="{name: 'Home'}">
          <img src="../../assets/images/logo.svg" alt="">
        </router-link>
      </div>
      <div class="navigation-right">
        <h3>Venez et apprenez ensemble ...</h3>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>