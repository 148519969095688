import Vuex from "vuex";
import Vue from "vue";

import users from "./modules/users";
import surveys from "./modules/surveys";
import questions from "./modules/questions";
import choices from "./modules/choices";
import categories from "./modules/categories";
import roles from "./modules/roles";
import avatars from "./modules/avatars";
import ratings from "./modules/ratings";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        users,
        surveys,
        questions,
        choices,
        categories,
        roles,
        avatars,
        ratings
    },
});

export default store;