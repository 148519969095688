<template src="../../templates/surveys/message.html"></template>

<script>
import MenuLeft from "../commons/MenuLeft.vue";
import AsideRight from "../commons/AsideRight.vue";

export default {
  components: {
    MenuLeft,
    AsideRight,
  },
  name: "Message",
  data() {
    return {};
  },
  methods: {
    handleBurger() {
      const burger = document.querySelector(".burger-block");
      const navigation = document.querySelector(".navigationAuth");
      burger.addEventListener("click", () => {
        navigation.classList.toggle("active");
      });
    },
  },
  computed: {},
  created() {},
};
</script>

<style scoped>
@import "../../assets/css/index.css";
</style>
