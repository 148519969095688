<template>
  <div class="footer">
    <div class="footer-container">
      <div class="footer-list">
        <p>{{ $t('footerConctactText') }}</p>
        <ul>
          <li><a href="tel:+491628996477">+49 162 8996477</a></li>
          <li><a href="mailto:support@winexam.help">support@winexam.help</a></li>
        </ul>
      </div>

      <div class="footer-list">
        <p>{{ $t('footerLinksText') }}</p>
        <ul>
          <li>
            <router-link :to="{ name: 'Policies' }">
              {{ $t('footerLink1Text') }}</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'TermsOfContract' }">
              {{ $t('footerLink2Text') }}</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'FAQs' }">{{ $t('footerLink3Text') }}</router-link>
          </li>
        </ul>
      </div>

      <div class="medias-footer">
        <a href="#">
          <img src="../../assets/images/facebook.svg" alt="facebook" />
        </a>
        <a href="#">
          <img src="../../assets/images/twitter.svg" alt="twitter" />
        </a>
        <a href="#">
          <img src="../../assets/images/linkedin.svg" alt="linkedin" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import "../../assets/css/index.css";
</style>
