<template src="../../templates/surveys/single-question.html">
	
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
	export default {
		data(){
			return {
				answers: []
			}
		},
		props: {
			question: {
				required: true,
				type: [Object],
			},
		},
		methods: {
			...mapActions([
				"addAnswerToQuestion"
			]),
			handelAnswer(){
				let selectedAnswer = this.choices.filter(
					elem => this.answers.includes(elem.id)
				);
				const isValid = selectedAnswer.length > 0 && selectedAnswer.map(
					elem => elem.is_answer
				).every((elem) => elem == true);
				this.addAnswerToQuestion({
					token: this.token,
					question: this.question.id,
					answer: isValid
				})
				this.$emit("answer", isValid);
			}
		},
		computed: {
			...mapGetters([
				"token",
				"choices"
			])
		},
		created(){

		}
	}
</script>

<style scoped>
@import "../../assets/css/index.css";
</style>
