<template>
  <nav class="navigationAuth">
    <div class="navigationAuth-logo">
      <img src="../../assets/images/logo.svg" alt="" />
    </div>
    <div class="navigationAuth-options">
      <router-link :to="{ name: 'SurveyList' }" tag="li">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M20 20a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9zM8 15v2h8v-2H8z"
            fill="rgba(27,31,60,1)"
          /></svg
        ><span>{{ $t('menuLeftHomeText') }}</span>
      </router-link>
      <router-link :to="{ name: 'Dashboard' }" tag="li">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"
            fill="rgba(27,31,60,1)"
          /></svg
        ><span>{{ $t('menuLeftDashboardText') }}</span>
      </router-link>
      <!--<router-link :to="{ name: 'Message' }" tag="li">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm9.06 8.683L5.648 6.238 4.353 7.762l7.72 6.555 7.581-6.56-1.308-1.513-6.285 5.439z"
            fill="rgba(27,31,60,1)"
          /></svg
        ><span>{{ $t('menuLeftMessageText') }}</span> 
      </router-link> -->
      <!-- <router-link :to="{ name: 'Notification' }" tag="li">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M22 20H2v-2h1v-6.969C3 6.043 7.03 2 12 2s9 4.043 9 9.031V18h1v2zM9.5 21h5a2.5 2.5 0 1 1-5 0z"
            fill="rgba(27,31,60,1)"
          /></svg
        ><span>{{ $t('menuLeftNotificationText') }}</span>
      </router-link> -->
      <router-link :to="{ name: 'Evolution' }" tag="li">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M22 20H2v-2h1v-6.969C3 6.043 7.03 2 12 2s9 4.043 9 9.031V18h1v2zM9.5 21h5a2.5 2.5 0 1 1-5 0z"
            fill="rgba(27,31,60,1)"
          /></svg
        ><span>{{ $t('menuLeftEvolutionText') }}</span>
      </router-link>
      <!-- <router-link :to="{ name: 'Settings' }" tag="li">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M9.954 2.21a9.99 9.99 0 0 1 4.091-.002A3.993 3.993 0 0 0 16 5.07a3.993 3.993 0 0 0 3.457.261A9.99 9.99 0 0 1 21.5 8.876 3.993 3.993 0 0 0 20 12c0 1.264.586 2.391 1.502 3.124a10.043 10.043 0 0 1-2.046 3.543 3.993 3.993 0 0 0-3.456.261 3.993 3.993 0 0 0-1.954 2.86 9.99 9.99 0 0 1-4.091.004A3.993 3.993 0 0 0 8 18.927a3.993 3.993 0 0 0-3.457-.26A9.99 9.99 0 0 1 2.5 15.121 3.993 3.993 0 0 0 4 11.999a3.993 3.993 0 0 0-1.502-3.124 10.043 10.043 0 0 1 2.046-3.543A3.993 3.993 0 0 0 8 5.071a3.993 3.993 0 0 0 1.954-2.86zM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
            fill="rgba(27,31,60,1)"
          /></svg
        ><span>{{ $t('menuLeftSettingsText') }}</span>
      </router-link> -->
      <router-link :to="{ name: 'Community' }" tag="li">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M9.954 2.21a9.99 9.99 0 0 1 4.091-.002A3.993 3.993 0 0 0 16 5.07a3.993 3.993 0 0 0 3.457.261A9.99 9.99 0 0 1 21.5 8.876 3.993 3.993 0 0 0 20 12c0 1.264.586 2.391 1.502 3.124a10.043 10.043 0 0 1-2.046 3.543 3.993 3.993 0 0 0-3.456.261 3.993 3.993 0 0 0-1.954 2.86 9.99 9.99 0 0 1-4.091.004A3.993 3.993 0 0 0 8 18.927a3.993 3.993 0 0 0-3.457-.26A9.99 9.99 0 0 1 2.5 15.121 3.993 3.993 0 0 0 4 11.999a3.993 3.993 0 0 0-1.502-3.124 10.043 10.043 0 0 1 2.046-3.543A3.993 3.993 0 0 0 8 5.071a3.993 3.993 0 0 0 1.954-2.86zM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
            fill="rgba(27,31,60,1)"
          /></svg
        ><span>{{ $t('menuLeftCommunityText') }}</span>
      </router-link>
    </div>
    <div class="navigationAuth-logout">
      <li @click="onLogout">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M5 22a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v3h-2V4H6v16h12v-2h2v3a1 1 0 0 1-1 1H5zm13-6v-3h-7v-2h7V8l5 4-5 4z"
            fill="rgba(27,31,60,1)"
          /></svg
        ><span>{{ $t('menuLeftLogoutText') }}</span>
      </li>
    </div>
  </nav>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "MenuLeft",
  data() {
    return {};
  },
  methods: {
    ...mapActions(["logout"]),
    async onLogout() {
      console.log("call logout function");
      const resp = await this.logout();
      if (resp) {
        this.$router.push({ name: "Home" });
      } else {
        console.log("An Error occurs in Logout process");
      }
    },
  },
  computed: {},
  created() {},
};
</script>

<style scoped>
@import "../../assets/css/index.css";
</style>
