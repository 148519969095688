import { render, staticRenderFns } from "../../templates/surveys/survey-questions.html?vue&type=template&id=92875e0e&scoped=true&"
import script from "./SurveyQuestions.vue?vue&type=script&lang=js&"
export * from "./SurveyQuestions.vue?vue&type=script&lang=js&"
import style0 from "./SurveyQuestions.vue?vue&type=style&index=0&id=92875e0e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92875e0e",
  null
  
)

export default component.exports