
import enGB from "./en-bg"
import fr from "./fr"
import deDe from "./de-de"

const message = {
    "en-gb": enGB,
    "de-de": deDe,
    "fr": fr,
};

export default message