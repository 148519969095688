import { ROLES_ENDPOINT } from "../settings";
import jwtInterceptor from "../../utils/jwtInterceptor";
import axios from "axios";

var state = {
    roles: []
}

var getters = {
    roles: (state) => state.role
}

var actions = {
    async getAllRoles({ commit }, data){
        try {
            const response = await axios.get(
                ROLES_ENDPOINT,
                {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response){
                commit("setRoles", response.data);
            }
        }catch(error){
            console.log(error);
        }
    },

    async addRole({ commit }, data){
        const body = {
            "name": data.role.name,
            "description": data.role.description
        };
        
        try{
           const response = await jwtInterceptor.post(
               ROLES_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("newRole", response.data);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async updateRole({ commit }, data){
        const body = {
            "id": data.role.id,
            "name": data.role.name,
            "description": data.role.description
        };
        
        try{
           const response = await jwtInterceptor.put(
               ROLES_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("updaterole", data.role);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async deleteRole({ commit }, data){
        const body = {
            "id": data.role.id
        };
        
        try{
           const response = await jwtInterceptor.delete(
               ROLES_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("deleteRole", data.role.id);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },
}

var mutations = {
    setRoles: (state, payload) => {
        state.roles = [].concat(payload);
    },
    newRole: (state, payload) => {
        state.roles.push(payload);
    },
    updateRole: (state, payload) => {
        const idx = state.roles.find(elem => elem.id == payload.id);
        state.roles[idx] = payload;
    },
    deleteRole: (state, payload) => {
        state.roles = state.roles.filter(elem => elem.id != payload);
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}