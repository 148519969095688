// const apiBaseEndPoint = "http://localhost:5000/v1/"
const apiBaseEndPoint = "https://api.winexam.net/v1/"

export const AXIOSCONFIG = {
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Accept": "application/json"
    }
};

export const LOGIN_ENDPOINT = apiBaseEndPoint + "auth";
export const USERS_ENDPOINT = apiBaseEndPoint + "users";
export const ACTIVATION_ENDPOINT =  apiBaseEndPoint + "activate-user";
export const FOLLOWERS_USERS_ENDPOINT = apiBaseEndPoint + "users/followers";
export const VERIFY_EMAIL_ENDPOINT = apiBaseEndPoint + "validate-email";
export const VERIFY_USERNAME_ENDPOINT = apiBaseEndPoint + "validate-username";
export const VERIFY_PHONE_ENDPOINT = apiBaseEndPoint + "validate-phone";
export const QUESTIONS_ENDPOINT = apiBaseEndPoint + "questions";
export const SURVEY_ENDPOINT = apiBaseEndPoint + "surveys";
export const CHOICES_ENDPOINT =  apiBaseEndPoint + "choices";
export const ANSWER_ENDPOINT =  apiBaseEndPoint + "answers";
export const TOKEN_ENDPOINT =  apiBaseEndPoint + "token";
export const CATEGORY_ENDPOINT =  apiBaseEndPoint + "category";
export const ROLES_ENDPOINT =  apiBaseEndPoint + "roles";
export const AVATARS_ENDPOINT =  apiBaseEndPoint + "avatars";
export const RATINGS_ENDPOINT =  apiBaseEndPoint + "ratings";
export const SOCIAL_LOGIN_ENDPOINT =  apiBaseEndPoint + "sociallogin";