import Home from "./components/Home.vue";
import Settings from "./components/surveys/Settings.vue";

import Login from "./components/users/Login.vue";
import Community from "./components/users/Community.vue";
import Register from "./components/users/Register.vue";
import Profile from "./components/users/Profile.vue";
import AccountActivation from "./components/users/AccountActivation.vue";
import SocialAuthCallback from "./components/users/SocialAuthCallback.vue";

import Dashboard from "./components/dashboard/Dashboard.vue";

import SurveyList from "./components/surveys/SurveyList.vue";
import SurveyQuestions from "./components/surveys/SurveyQuestions.vue";
import Evolution from "./components/surveys/Evolution.vue";
import Message from "./components/surveys/Message.vue";
import Responses from "./components/surveys/Responses.vue";
import Test from "./components/Test.vue";

import Error404 from "./components/Error404.vue";

import TermsOfContract from "./components/pages/TermsOfContract.vue";
import Policies from "./components/pages/Policies.vue";
import FAQs from "./components/pages/FAQs.vue";

export default [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/terms-of-contract",
    name: "TermsOfContract",
    component: TermsOfContract,
  },
  {
    path: "/policy",
    name: "Policies",
    component: Policies,
  },
  {
    path: "/faqs",
    name: "FAQs",
    component: FAQs,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/logincallback/:provider",
    name: "SocialAuthCallback",
    component: SocialAuthCallback,
    props: route => Object.assign({}, route.query, { provider: route.params.provider })
  },
  {
    path: "/activate-user",
    name: "AccountActivation",
    component: AccountActivation,
    props: route => Object.assign({}, route.query)
  },
  {
    path: "/surveys",
    name: "SurveyList",
    component: SurveyList,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/survey-questions",
    name: "SurveyQuestions",
    component: SurveyQuestions,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/evolution",
    name: "Evolution",
    component: Evolution,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/message",
    name: "Message",
    component: Message,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/responses",
    name: "Responses",
    component: Responses,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
    /*meta: {
      requiredAuth: true
    }*/
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/community",
    name: "Community",
    component: Community,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "*",
    name: "Error404",
    component: Error404
  },
]