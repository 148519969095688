<template src="../../templates/surveys/survey-questions.html"></template>

<script>
import { mapActions, mapGetters } from "vuex";
import MenuLeft from "../commons/MenuLeft.vue";
import AsideRight from "../commons/AsideRight.vue"
import SingleQuestion from "./SingleQuestion.vue"

export default {
  title: "Question",
  name: "SurveyQuestion",
  components: {
    MenuLeft,
    AsideRight,
    SingleQuestion
  },
  data() {
    return {
      currentQuestion: {},
    };
  },
  methods: {
    ...mapActions([
      "getQuestions",
      "getChoices"
    ]),
    async changeCurrentQuestion(question) {
      this.currentQuestion = { ...question };
      await this.getChoices({
        question: question.id,
        token: this.token
      });
    },
    checkQuestion(responses) {
      return responses
        .map((elem) => elem.is_answer)
        .every((elem) => elem == true);
    },
    handleBurger() {
      const burger = document.querySelector(".burger-block");
      const navigation = document.querySelector(".navigationAuth");
      burger.addEventListener("click", () => {
        navigation.classList.toggle("active");
      });
    },
    nextQuestion(){
      const currentIdx = this.questions.findIndex(elem => elem.id === this.currentQuestion.id);
      if(currentIdx < this.questions.length - 1){
        this.changeCurrentQuestion(this.questions[currentIdx + 1]);
      }
    },
    previewQuestion(){
      const currentIdx = this.questions.findIndex(elem => elem.id === this.currentQuestion.id);
      if(currentIdx > 0){
        this.changeCurrentQuestion(this.questions[currentIdx - 1]);
      }
    },
    handelAnswer(response){
      console.log(response);
      if(response){
        this.nextQuestion();
      }else{
        console.log("Your Answer is false try again");
      }
    }
  },
  computed: {
    ...mapGetters([
      "user",
      "token",
      "questions",
      "selectedSurvey"
    ]),
  },
  async created() {
    console.log("props", this.survey);
    await this.getQuestions({
      token: this.token,
      survey: this.selectedSurvey
    });
    await this.changeCurrentQuestion(this.questions[0]);
    await this.getChoices({
      question: this.questions[0].id,
      token: this.token
    });
    console.log(this.currentQuestion);
  },
};
</script>

<style scoped>
@import "../../assets/css/index.css";
</style>
