import axios from "axios";
import {
    CHOICES_ENDPOINT
} from "../settings";

var state = {
    choices: []
}

var getters = {
    choices: (state) => state.choices,
}

var actions = {
    async getChoices({ commit }, data){
        const body = {
            question: data.question
        }
        try {
            const response = await axios.get(
                CHOICES_ENDPOINT,
                {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    },
                    params: {
                        ...body
                    }
                }
            );
            if(response){
                commit("setChoices", response.data);
            }
        }catch(error){
            console.log(error);
        }
    },
}

var mutations = {
    setChoices: (state, payload) => {
        /*eslint no-unused-vars: "error"*/
        state.choices = [].concat(payload.sort((a, b) => 0.5 - Math.random()));
        /*eslint no-unused-vars: 0*/
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}