import axios from "axios";
import store from "../store/index";
import { TOKEN_ENDPOINT } from "../store/settings";
 
const jwtInterceptor = axios.create({});
 
 
jwtInterceptor.interceptors.request.use((config) => {
    //const authData = store.getters["auth/getAuthData"];
    if (store.getters.token == null) {
        return config;
    }
  
    config.headers.common["Authorization"] = `bearer ${store.getters.token}`;
    config.headers.common["Access-Control-Allow-Origin"] = "*";
    config.headers.common["Content-Type"] = "application/json";
    return config;
});
  
jwtInterceptor.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
        if (error.response.status === 401) {
            const payload = {
                access_token: store.getters.token,
                refresh_token: store.getters.refreshToken,
            };

            error.config.headers["Authorization"] = `bearer ${store.getters.refreshToken}`;
  
            var response = await axios.put(
                TOKEN_ENDPOINT,
                payload
            );
            await store.dispatch("setToken", response.data.token);
            error.config.headers[
                "Authorization"
            ] = `bearer ${response.data.token}`;
            return axios(error.config);
        }else{
            return Promise.reject(error);
        }
    }
);
  
export default jwtInterceptor;