<template src="../../templates/users/community.html">
   
</template>

<script>
import MenuLeft from "../commons/MenuLeft.vue";
import AsideRight from "../commons/AsideRight.vue";

export default{
	title: "Community",
	name: "Community",
		components: {
		MenuLeft,
		AsideRight
  },
	data() {
		return {
			
		};
	},
	methods: {
		
	},
	computed: {
		
	},
	created() {
		
	},
}
</script>

<style scoped>

</style>