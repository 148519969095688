<template>
	<div>
		Social Auth Callback
	</div>
</template>

<script>
export default {
  title: "redirect...",
	name: "SocialAuthCallback",
	data() {
		return {};
	},
	props: {
		provider: {
			type: String,
		},
		code:{
			type: String,
		},
		status:{
			type: Object,
		},
		access_token:{
			type: String,
		},
	},
	methods: {
		//
	},
	computed: {
		//
	},
	async created() {
		console.log("provider ", this.provider);
		console.log("status ", this.status);
		console.log("code ", this.code);
		console.log("access_token ", this.access_token);

		// const component = this.$root.$options.components["SocialAuthCallback"];
		// let props = component.options.props;
	},
}
</script>

<style scoped>
	@import "../../assets/css/index.css";
</style>