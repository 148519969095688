import axios from "axios";
import { AVATARS_ENDPOINT } from "../settings";
import jwtInterceptor from "../../utils/jwtInterceptor";

var state = {
    avatars: []
}

var getters = {
    avatars: (state) => state.avatars
}

var actions = {
    async getAllAvatars({ commit }, data){
        console.log("category data", data);
        try {
            const response = await axios.get(
                AVATARS_ENDPOINT,
                {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response){
                commit("setAvatars", response.data);
            }
        }catch(error){
            console.log(error);
        }
    },

    async addAvatar({ commit }, data){
        const body = {
            "name": data.avatar.name,
            "file": data.avatar.file
        };
        
        try{
           const response = await jwtInterceptor.post(
               AVATARS_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("newAvatar", response.data);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async updateAvatar({ commit }, data){
        const body = {
            "avatar": data.avatar.id,
            "name": data.avatar.name,
            "file": data.avatar.file
        };
        
        try{
           const response = await jwtInterceptor.put(
               AVATARS_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("updateAvatar", response.data.avatar);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async deleteAvatar({ commit }, data){
        const body = {
            "avatar": data.avatar.id
        };
        
        try{
           const response = await jwtInterceptor.delete(
               AVATARS_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("deleteAvatar", data.avatar.id);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },
}

var mutations = {
    setAvatars: (state, payload) => {
        state.avatars = [].concat(payload);
    },
    newAvatar: (state, payload) => {
        state.avatars.push(payload);
    },
    updateAvatar: (state, payload) => {
        const idx = state.avatars.find(elem => elem.id == payload.id);
        state.avatars[idx] = payload;
    },
    deleteAvatar: (state, payload) => {
        state.avatars = state.avatars.filter(elem => elem.id != payload);
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}