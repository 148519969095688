import axios from "axios";
import { SURVEY_ENDPOINT, AXIOSCONFIG } from "./../settings";
// import jwtInterceptor from "../../utils/jwtInterceptor";

const state = {
    surveys: [],
    selectedSurvey: null
}

const getters = {
    surveys: (state) => state.surveys,
    selectedSurvey: (state) => state.selectedSurvey
}

const actions = {
    async getAllSurveys({ commit }, data){
        console.log("survey data ", data);
        try{
            let body = {}
            if(typeof data != "undefined" && "survey_id" in data){
                body.survey = data.survey_id;
            }
            if(typeof data != "undefined" && "user" in data){
                body.user = data.user;
            }

            console.log("body", body)

            const response = await axios.get(
                SURVEY_ENDPOINT,
                {
                    headers: {
                        "Authorization": `Bearer ${data.token}`,
                        ...AXIOSCONFIG.headers
                    },
                    params: {
                        ...body
                    }
                }
                
            );
            
            const surveys = response.data;
            if(surveys){
                commit("allSurveys", surveys);
                return true
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async getSurveyByUser({ commit }, data){
        try{
            let formData = new FormData();
            formData.append("user_id", data.user.id);

            const response = await axios.get(
                SURVEY_ENDPOINT,
                formData
            );
            
            const surveys = response.data;
            if(surveys){
                commit("userSurveys", surveys);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async addSurvey({ commit }, data){
        console.log("data", data)
        try{
            const body = {
                "title": data.survey.title,
                "description": data.survey.description,
                "category": data.survey.category,
                "avatar": data.survey.avatar
            }

            const response = await axios.post(
                SURVEY_ENDPOINT,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                    }
                }

                
            );

            if(response){
                commit("addSurvey", response.data.survey);
                return true
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async updateSurvey({ commit }, data){
        try{
            const body = {
                survey: data.survey.id,
                title: data.survey.title,
                avatar: data.survey.avatar,
                description: data.survey.description
            }

            const response = await axios.put(
                SURVEY_ENDPOINT,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        ...AXIOSCONFIG.headers
                    }
                },
            );
            
            const survey = data.survey;
            if(response.data.ok){
                commit("updateSurvey", survey);
                return true
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async deleteSurvey({ commit }, data){
        try{
            const body = {
                survey: data.survey.id
            }

            console.log("data", data);
            console.log("body", body);

            const response = await axios.delete(
                SURVEY_ENDPOINT,
                {
                    data: {...body},
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                        ...AXIOSCONFIG.headers
                    }
                },
            );
            
            const surveyId = response.data.id;
            if(response.data.ok){
                commit("deleteSurvey", surveyId);
                return true
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    setSelectedSurvey({ commit }, value){
        commit("setSelectedSurvey", value)
    }
}

const mutations = {
    allSurveys: (state, data) => {
        state.surveys = [].concat(data);
    },
    userSurveys: (state, data) => {
        state.surveys = [].concat(data);
    },
    addSurvey: (state, payload) => {
        state.surveys.push(payload);
    },
    updateSurvey: (state, data) => {
        const idx = state.surveys.findIndex(elem => elem.id = data.id);
        state.surveys[idx] = {...data};
    },
    deleteSurvey: (state, data) => {
        state.surveys = state.surveys.filter(elem => elem.id !== data);
    },
    setSelectedSurvey: (state, value) => {
        state.selectedSurvey = value
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}