<template>
	<div>
		<canvas id="linechart"></canvas>
	</div>
</template>

<script>
	import Chart from "chart.js"

	export default {
		name: "LineChart",
		props:{
			data: {
				type: Object,
				required: true
			}
		},
		mounted(){
			const ctx = document.getElementById("linechart");
			new Chart(ctx, this.data);
		}
	}
</script>

<style>

</style>