<template src="../../templates/dashboard/dashboard.html"></template>

<script>
import MenuLeft from "../commons/MenuLeft.vue";
import AsideRight from "../commons/AsideRight.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  title: "dashboard",
  name: "Dashboard",
  components: {
    MenuLeft,
    AsideRight,
  },
  data() {
    return {
      newAvatar: {
        name: "",
        file: null,
      },
      newCategory: {
        name: "",
        description: "",
      },
      newSurvey: {
        name: "",
        description: "",
        avatar: 0,
      },
      updatedSurvey: {
        name: "",
        description: "",
        avatar: 0,
      },
      deletedSurvey: {
        name: "",
        description: "",
        avatar: 0,
      },
      newQuestion: {
        text: "",
        explanation: "",
        survey: 0,
        is_multi: true,
        choices: [],
      },
      newChoice: {
        text: "",
        is_answer: false,
      },
    };
  },
  methods: {
    ...mapActions([
      "getAllSurveys", "addSurvey", "deleteSurvey", "updateSurvey",
      "getAllAvatars", "addAvatar", "updateAvatar", "deleteAvatar",
      "getAllCategories", "addCategory", "updateCategory", "deleteCategory",
      "addQuestion",
    ]),
    openNewSurveyModal() {
      document.querySelector("body").classList.add("active");
      document.querySelector("#quiz-survey").classList.add("active");
    },
    openNewQuestionModal() {
      document.querySelector("body").classList.add("active");
      document.querySelector("#quiz-question").classList.add("active");
    },
    openUpdateSurveyModal() {
      document.querySelector("body").classList.add("active");
      document.querySelector("#update-survey").classList.add("active");
    },
    openDeleteSurveyModal() {
      document.querySelector("body").classList.add("active");
      document.querySelector("#delete-survey").classList.add("active");
    },
    closeNewSurveyModal() {
      document.querySelector("body").classList.remove("active");
      document.querySelector("#quiz-survey").classList.remove("active");
    },
    closeNewQuestionModal() {
      document.querySelector("body").classList.remove("active");
      document.querySelector("#quiz-question").classList.remove("active");
    },
    closeDeleteSurveyModal() {
      document.querySelector("body").classList.remove("active");
      document.querySelector("#delete-survey").classList.remove("active");
    },
    closeUpdateSurveyModal() {
      document.querySelector("body").classList.remove("active");
      document.querySelector("#update-survey").classList.remove("active");
    },
    handleBurger() {
      const burger = document.querySelector(".burger-block");
      const navigation = document.querySelector(".navigationAuth");
      burger.addEventListener("click", () => {
        navigation.classList.toggle("active");
      });
    },
    initNewSurvey() {
      this.newSurvey = {
        name: "",
        description: "",
        avatar: 0,
      };
    },
    setNewSureyAvatar(avatar) {
      this.newSurvey.avatar = avatar;
    },
    setToUpdatedSurvey(survey) {
      this.updatedSurvey = {...survey};
      console.log(this.updatedSurvey);
      this.openUpdateSurveyModal();
    },
    setUpdatedSureyAvatar(avatar) {
      this.updatedSurvey.avatar = avatar;
    },
    setToDeletedSurvey(survey) {
      this.deletedSurvey = {...survey};
      this.openDeleteSurveyModal();
    },
    initNewAvatar() {
      this.newAvatar = {
        name: "",
        file: null,
      };
    },
    initNewCategory() {
      this.newAvatar = {
        name: "",
        file: null,
      };
    },
    initNewQuestion() {
      this.newQuestion = {
        text: "",
        explanation: "",
        survey: 0,
        is_multi: true,
        choices: [],
      };
    },
    initNewChoice() {
      this.newChoice = {
        text: "",
        is_answer: false,
      };
    },
    async onAddSurvey() {
      const resp = await this.addSurvey({
        token: this.token,
        survey: this.newSurvey,
      });
      if (resp) {
        console.log("the survey has been sucessfully added");
        this.initNewSurvey();
        this.closeNewSurveyModal();
      } else {
        console.log("An error occurs!");
        this.closeNewSurveyModal();
      }
    },
    async onDeleteSurvey(){
      const resp = await this.deleteSurvey({
        token: this.token,
        survey: this.deletedSurvey,
      });
      if (resp) {
        console.log("the survey has been sucessfully deleted");
        this.closeDeleteSurveyModal();
      } else {
        console.log("An error occurs!");
        this.closeDeleteSurveyModal();
      }
    },
    async onUpdateSurvey(){
      const resp = await this.updateSurvey({
        token: this.token,
        survey: this.updatedSurvey,
      });
      if (resp) {
        console.log("the survey has been sucessfully updated");
        this.closeUpdateSurveyModal();
      } else {
        console.log("An error occurs!");
        this.closeUpdateSurveyModal();
      }
    },
    addNewChoiceToQuestion() {
      if (!(this.newChoice.text === "")) {
        this.newQuestion.choices.push(this.newChoice);
      } else {
        alert("Le champ est vide !");
      }
      this.initNewChoice();
      console.log(this.newQuestion);
    },
    async onAddQuestion(e) {
      e.preventDefault();
      let hasMulti =
        this.newQuestion.choices.length > 0 &&
        this.newQuestion.choices.filter((elem) => elem.is_answer).length > 1;
      this.newQuestion.is_multi = hasMulti ? true : false;
      const resp = await this.addQuestion({
        token: this.token,
        question: this.newQuestion,
      });
      console.log(resp);
      if (resp) {
        this.initNewQuestion();
      }
    },
    async createAvatar() {
      const resp = await this.addAvatar({
        token: this.token,
        category: this.newAvatar,
      });
      console.log(resp);
      if (resp) {
        this.initNewAvatar();
      }
    },
    async changeAvatar(avatar) {
      const resp = await this.addAvatar({
        token: this.token,
        avatar: avatar,
      });
      console.log(resp);
    },
    async removeAvatar(avatar) {
      const resp = await this.addAvatar({
        token: this.token,
        avatar: avatar,
      });
      console.log(resp);
    },
    async createCategory() {
      const resp = await this.addAvatar({
        token: this.token,
        category: this.newCategory,
      });
      console.log(resp);
      if (resp) {
        this.initNewAvatar();
      }
    },
    async changeCategory(category) {
      const resp = await this.addAvatar({
        token: this.token,
        category: category,
      });
      console.log(resp);
    },
    async removeCategory(category) {
      const resp = await this.addAvatar({
        token: this.token,
        category: category,
      });
      console.log(resp);
    },
  },
  computed: {
    ...mapGetters(["token", "user", "surveys", "avatars", "categories"]),
  },
  async created() {
    await this.getAllSurveys({
      token: this.token,
      user: this.user.id,
    });
  },
  mounted() {},
};
</script>

<style scoped>
@import "../../assets/css/index.css";
</style>
