<template src="../../templates/surveys/responses.html">
    
</template>

<script>
	export default {
		name: "Response",
		data(){
			return {

			}
		},
		methods: {
			quiz(){
				document.querySelector('body').classList.toggle('active')
        document.querySelector('#quiz').classList.toggle('active')
			}
		}
	}
</script>

<style scoped>
	@import "../../assets/css/index.css";
</style>