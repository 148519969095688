<template src="../../templates/surveys/evolution.html">
    
</template>

<script>
	import LineChart from "../utils/LineChart.vue";
	import CircleChart from "../utils/CircleChart.vue";
  import MenuLeft from "../commons/MenuLeft.vue";
  import AsideRight from "../commons/AsideRight.vue";

  export default {
		name: "Evolution",
		components: {
			LineChart,
      CircleChart,
      MenuLeft,
      AsideRight
		},
		data(){
			return {
				chartdata: {
          type: "line",
          data: {
            labels: [
              'Lundi',
              'Mardi',
              'Mercredi',
              'Jeudi',
              'Vendredi',
              'Samedi',
              'Dimanche'
            ],
            datasets: [{
              label: 'Hebdomadaire',
              data: [10, 30, 7, 13, 25, 10, 5],
              backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(54, 162, 235)',
              'rgb(255, 205, 86)'
              ],
              fill: false,
              tension: 0.1
            }],
            options: {
              responsive: true,
              lineTension: 1,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      padding: 25
                    }
                  }
                ]
              }
            }
          }
				},
				circledata: {
          type: "doughnut",
          data: {
            labels: [
              'Maths',
              'Physique',
              'Informatique'
            ],
            datasets: [{
              label: 'My First Dataset',
              data: [55, 25, 20],
              backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(54, 162, 235)',
              'rgb(255, 205, 86)'
              ],
              hoverOffset: 4
            }]
          }
				}
			}
		},
		methods: {

		},
		computed: {

		},
		created(){

		}
	}
</script>

<style scoped>
	@import "../../assets/css/index.css";
</style>