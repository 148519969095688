<template src="../../templates/pages/faqs.html">

</template>

<script>
	import Header from "../commons/Header.vue";
	import Footer from "../commons/Footer.vue";

	export default {
		title: "Frequently Asked Questions",
		name: 'FAQs',
		components: {
			Header,
			Footer
		},
		data() {
			return {
				//
			}
		},
		methods: {
			//
		}
	}
</script>

<style scoped>
@import "../../assets/css/index.css";

.faqs{
	background-image: repeating-linear-gradient(135deg, rgba(189, 189, 189, 0.1) 0px, rgba(189, 189, 189, 0.1) 2px, transparent 2px, transparent 4px), linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
}

.faqs-container{
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.faqs-container p{
	font-size: 30px;
}

.faqs-container span{
	color: #ffab02;
	font-size: 20px;
	font-weight: bold;
	text-decoration: underline;
}
</style>