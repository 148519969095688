<template src="../templates/test.html">
    
</template>

<script>
    import MenuLeft from "./commons/MenuLeft.vue";

    export default {
        name: "Test",
        components: {
            MenuLeft
        },
        data(){
            return {
                can: "la Can est sucree"
            }
        },
        methods: {
            updateText(){
                this.can = "je viens de changer le texte sur la page"
            }
        }
    }
</script>

<style scoped>

</style>