<template src="../../templates/surveys/survey-list.html"></template>

<script>
import { mapActions, mapGetters } from "vuex";
import MenuLeft from "../commons/MenuLeft.vue";
import AsideRight from "../commons/AsideRight.vue";
// import StarRating from "../utils/StarRating.vue";
import StarRating from "vue-star-rating";

export default {
  title: "Surveys",
  components: {
    MenuLeft,
    AsideRight,
    StarRating
  },
  name: "SurveyList",
  data() {
    return {
      newSurvey: {
        title: "",
        description: "",
        avatar: 1,
        category: 1,
      },
    };
  },
  methods: {
    ...mapActions([
      "getAllSurveys",
      "addSurvey",
      "getAllCategories",
      "getAllAvatars",
      "setSelectedSurvey"
    ]),
    initNewSurvey() {
      this.newSurvey = {
        title: "",
        description: "",
        avater: 1,
        category: 1,
      };
    },
    routeToQuestion(value) {
      this.setSelectedSurvey(value);
      this.$router.push({ name: "SurveyQuestions"});
    },
    handleBurger() {
      const burger = document.querySelector(".burger-block");
      const navigation = document.querySelector(".navigationAuth");
      burger.addEventListener("click", () => {
        navigation.classList.toggle("active");
      });
    },
    openRateSurveyModal() {
      document.querySelector("body").classList.add("active");
      document.querySelector("#rate-survey").classList.add("active");
    },
    closeRateSurveyModal() {
      document.querySelector("body").classList.remove("active");
      document.querySelector("#rate-survey").classList.remove("active");
    },
    onRateSurvey(){
      
    }
  },
  computed: {
    ...mapGetters(["token", "user", "surveys", "categories", "avatars"]),
  },
  async created() {
    await this.getAllSurveys({
      token: this.token
    });
    await this.getAllCategories({
      token: this.token,
    });
    await this.getAllAvatars({
      token: this.token,
    });
    console.log(this.user);
    console.log(this.surveys);
    console.log(this.avatars);
  },
};
</script>

<style scoped>
@import "../../assets/css/index.css";
</style>
