<template src="../../templates/users/register.html"></template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import Header from "../commons/Header.vue";
import Footer from "../commons/Footer.vue";

export default {
  name: "Register",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      newUser: {
        username: "",
        email: "",
        firstname: "",
        name: "",
        phone: "",
        password: "",
      },
      EmailVerif: {},
      vueTel: {
        props: {
          preferredCountries: ["US", "GB"],
          placeholder: "Enter your phone",
          mode: "international",
          inputOptions: {
            showDialCode: true,
          },
          disabledFormatting: false,
          wrapperClasses: "country-phone-input",
        },
      },
    };
  },
  methods: {
    ...mapActions([
      "addUser",
      "verifyEmail",
      "verifyUsername",
      "verifyPhoneNumber",
    ]),
    async onNewUser() {
      const isValidPhone = await this.verifyPhoneNumber(this.newUser.phone);
      const isValidUsername = await this.verifyPhoneNumber(
        this.newUser.username
      );
      const isValidEmail = await this.verifyEmail(this.newUser.email);
      console.log(
        "phone ",
        isValidPhone,
        "\nemail ",
        isValidEmail,
        "\nusername ",
        isValidUsername
      );
      if (!isValidPhone){
        console.log("The phone number is not valid");
      }
      if (!isValidUsername){
        console.log("The username number is not valid");
      }
      if (!isValidEmail.ok){
        console.log("The email number is not valid");
      }
      const resp = await this.addUser({ user: this.newUser });
      if (resp) {
        console.log(this.message);
        this.$router.push({ name: "Home" });
      } else {
        console.log(
          "An error occurs and the system was not able to create the new user"
        );
      }
    },
    async checkValidEmail(email) {
      const resp = await this.verifyEmail(email);
      this.EmailVerif = { ...resp };
    },
  },
  computed: {
    ...mapGetters(["message"]),
  },
  mounted(){},
};

const input = document.querySelectorAll(".register-container-form input");

input.forEach((element) => {
  element.addEventListener("input", (e) => {
    if (e.target.value !== "") {
      e.target.parentElement.classList.add("active-input");
    }
    if (e.target.value == "") {
      e.target.parentElement.classList.remove("active-input");
    }
  });
});
</script>

<style scoped>
@import "../../assets/css/index.css";
</style>