import axios from "axios";
import { CATEGORY_ENDPOINT } from "../settings";
import jwtInterceptor from "../../utils/jwtInterceptor";

var state = {
    categories: []
}

var getters = {
    categories: (state) => state.categories
}

var actions = {
    async getAllCategories({ commit }, data){
        console.log("category data", data);
        try {
            const response = await axios.get(
                CATEGORY_ENDPOINT,
                {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response){
                commit("setCategories", response.data);
            }
        }catch(error){
            console.log(error);
        }
    },

    async addCategory({ commit }, data){
        const body = {
            "name": data.category.name,
            "description": data.category.description
        };
        
        try{
           const response = await jwtInterceptor.post(
               CATEGORY_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("newCategory", response.data);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async updateCategory({ commit }, data){
        const body = {
            "id": data.category.id,
            "name": data.category.name,
            "description": data.category.description
        };
        
        try{
           const response = await jwtInterceptor.put(
               CATEGORY_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("updateCategory", data.category);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async deleteCategory({ commit }, data){
        const body = {
            "id": data.category.id
        };
        
        try{
           const response = await jwtInterceptor.delete(
               CATEGORY_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("deleteCategory", data.category.id);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },
}

var mutations = {
    setCategories: (state, payload) => {
        state.categories = [].concat(payload);
    },
    newCategory: (state, payload) => {
        state.categories.push(payload);
    },
    updateCategory: (state, payload) => {
        const idx = state.categories.find(elem => elem.id == payload.id);
        state.categories[idx] = payload;
    },
    deleteCategory: (state, payload) => {
        state.categories = state.categories.filter(elem => elem.id != payload);
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}