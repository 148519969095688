<template src="../../templates/pages/policies.html">

</template>

<script>
	import Header from "../commons/Header.vue";
	import Footer from "../commons/Footer.vue";

	export default {
		title: "Policies",
		name: "Policies",
		components: {
			Header,
			Footer
		},
		data() {
			return {
				//
			}
		},
		methods: {
			//
		}
	}
</script>

<style scoped>
@import "../../assets/css/index.css";

.policies{
	background-image: repeating-linear-gradient(135deg, rgba(189, 189, 189, 0.1) 0px, rgba(189, 189, 189, 0.1) 2px, transparent 2px, transparent 4px), linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
}

.policies-container{
	min-height: 100vh;
	margin-top: 80px;
	padding: 2rem;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
}

.policies-container h1, h2{
	color: #ffab02;
	font-weight: 400;
}
</style>