<template src="../../templates/users/login.html"></template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import Header from "../commons/Header.vue";
import Footer from "../commons/Footer.vue";

export default {
  title: "Login",
  name: "Login",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      logData: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions([
      "login",
      "loginWithProvider"
    ]),
    async onLogin() {
      const resp = await this.login({ user: this.logData });
      console.log("response ", resp);
      if (resp) {
        await this.$router.push({ name: "SurveyList" });
      } else {
        console.log("username or password is incorect");
      }
    },
    AuthProvider(provider){
      this.loginWithProvider(provider);
    },
  },
  computed: {
    ...mapGetters(["message"]),
  },
  mounted() {},
};

const input = document.querySelectorAll('.login-container-form input');

input.forEach(element => {
    element.addEventListener('input',(e)=>{
        if(e.target.value !== ""){
            e.target.parentElement.classList.add('active-input');
        }
        if(e.target.value == ""){
            e.target.parentElement.classList.remove('active-input');
        }
    });
});

</script>

<style scoped>
@import "../../assets/css/index.css";
</style>
