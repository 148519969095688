import { RATINGS_ENDPOINT } from "../settings";
import jwtInterceptor from "../../utils/jwtInterceptor";

var state = {

}

var getters = {
    
}

var actions = {
    async addRating(_, data){
        const body = {
            "survey": data.survey,
            "rate": data.rate
        };
        if("comment" in data && data.comment !== undefined){
            body.comment = data.comment;
        }
        
        try{
           const response = await jwtInterceptor.post(
               RATINGS_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response){
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },
}

var mutations = {

}

export default {
    state,
    getters,
    actions,
    mutations
}
