<template>
    <div>
			Account Activation
		</div>
</template>

<script>
import { mapActions } from 'vuex'
	export default {
		title: "account's activation",
		name: "AccountActivation",
		data(){
			return {

			}
		},
		props: {
			token: {
				type: String,
				required: true
			}
		},
		methods: {
			...mapActions([
				"activateAccount"
			]),
			async handelactivation(){
				const resp = await this.activateAccount({
					token: this.token
				});
				if(resp){
					console.log("the activation was successfull");
				}else{
					console.log("a problem occurs during the activation!!!");
				}
			}
		},
		computed: {

		},
		async created(){
			await this.handelactivation();
		}
	}
</script>

<style scoped>

</style>