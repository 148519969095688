import axios from "axios";
// import Vue from "vue";
import {
    LOGIN_ENDPOINT,
    USERS_ENDPOINT,
    ACTIVATION_ENDPOINT,
    AXIOSCONFIG,
    TOKEN_ENDPOINT,
    FOLLOWERS_USERS_ENDPOINT,
    VERIFY_EMAIL_ENDPOINT,
    VERIFY_USERNAME_ENDPOINT,
    VERIFY_PHONE_ENDPOINT,
    SOCIAL_LOGIN_ENDPOINT
} from "./../settings";
import { jwtDecrypt, tokenAlive } from "../../utils/index";
// import jwtInterceptor from "../../utils/jwtInterceptor";


var state = {
    user: {
        id: "",
        username: "",
        email: "",
        firstname: "",
        name: "",
        phone: "",
        numSurvey: 0
    },
    token: "",
    refreshToken: "",
    tokenExp: "",
    message: ""
}

var getters = {
    isLoggedIn: state => state.token !== "", //!!state.user,
    user: state => state.user,
    token: state => state.token,
    refreshToken: state => state.refreshToken,
    tokenExp: state => state.tokenExp,
    message: state => state.message,
    isTokenActive: state => {
        if(!state.tokenExp){
            return false;
        }
        return tokenAlive(state.tokenExp);
    }
}

var actions = {
    async login({ commit }, data){
        const body = {
            "username": data.user.username,
            "password": data.user.password
        };

        try{
            const response = await axios.post(
                LOGIN_ENDPOINT,
                body,
                AXIOSCONFIG
            );
            
            if(response.data.ok){
                const jwtDecodedValue = jwtDecrypt(response.data.token);
                console.log("decode jwt", jwtDecodedValue);
                console.log("message", response.data.message);
                await commit("setToken", response.data.token);
                await commit("setRefreshToken", response.data.refreshToken);
                await commit("setTokenExp", jwtDecodedValue.exp);
                await commit("setUserId", jwtDecodedValue.sub);
                await commit("setMessage", response.data.message);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async getSocialAuth(_, provider){
        await axios.get(
            SOCIAL_LOGIN_ENDPOINT + `/${provider}`
        )
    },

    async getUserInfos({ commit }, data){
        try {
            const response = await axios.get(
                TOKEN_ENDPOINT,
                {
                    headers: {
                        Authorization: `Bearer ${data.token}`,
                    }
                }
            );
            console.log("resp", response);
            if(response){
                commit("setUserInfos", response.data);
            }
        }catch(error){
            console.log(error);
        }
    },

    async logout({ commit }){
        try{
            await commit("logout");
            return true;
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async addUser({ commit }, data){
        const body = {
            "username": data.user.username,
            "email": data.user.email,
            "firstname": data.user.firstname,
            "name": data.user.name,
            "phone": data.user.phone,
            "password": data.user.password
        };
        
        try{
           const response = await axios.post(
               USERS_ENDPOINT,
               body,
               AXIOSCONFIG
            );
            if(response.data.ok){
                commit("newUser", response.data);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async activateAccount(_, data){
        const body = {
            token: data.token,
        };
        
        try{
           const response = await axios.post(
               ACTIVATION_ENDPOINT,
               body,
               AXIOSCONFIG
            );
            
            return response.data.ok;

        }catch(error){
            console.error(error);
            return false;
        }
    },

    async updateUser({ commit }, data){
        const body = {
            "username": data.user.username,
            "email": data.user.email,
            "firstname": data.user.firstname,
            "name": data.user.name,
            "phone": data.user.phone,
            "password": data.user.password
        };
        try{
           const response = await axios.put(
                USERS_ENDPOINT,
                body,
                AXIOSCONFIG
            );
            if(response.data.ok){
                commit("updateUser", response.data.token);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async deleteUser({ commit }, data){
        const body = {
            "id": data.user.id,
        };
        try{
           const response = await axios.delete(
                USERS_ENDPOINT,
                body,
                AXIOSCONFIG
            );
            if(response.data.ok){
                commit("deleteUser", response.data.token);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async followUser({ commit }, data){
        const body = {
            "user": data.user.id,
        };
        try{
           const response = await axios.post(
                FOLLOWERS_USERS_ENDPOINT,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("followUser", response.data.user);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async unfollowUser({ commit }, data){
        const body = {
            "user": data.user.id,
        };
        try{
           const response = await axios.delete(
                FOLLOWERS_USERS_ENDPOINT,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("unfollowUser", response.data.user);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async verifyEmail(_, email){
        const body = {
            "email": email,
        };
        try{
           const response = await axios.post(
                VERIFY_EMAIL_ENDPOINT,
                body,
            );
            if(response){
                return response.data;
            }
        }catch(error){
            console.error(error);
            return {
                ok: false,
                message: ""
            };
        }
    },

    async verifyUsername(_, username){
        const body = {
            "username": username,
        };
        try{
           const response = await axios.post(
                VERIFY_USERNAME_ENDPOINT,
                body,
            );
            if(response){
                return response.data.ok;
            }
        }catch(error){
            return false;
        }
    },

    async verifyPhoneNumber(_, phone){
        const body = {
            "phone": phone,
        };
        try{
           const response = await axios.post(
                VERIFY_PHONE_ENDPOINT,
                body,
            );
            if(response){
                return response.data;
            }
        }catch(error){
            return null;
        }
    },

    async loginWithProvider(_, provider){
        const rest = await axios.get(SOCIAL_LOGIN_ENDPOINT + `/${provider}`);
        console.log("rest", rest);
        window.location.href = rest.data;
    }
}

var mutations = {
    newUser: (state, data) => {
        state.message = data.msg;
    },
    setUser: (state, data) => {
        state.user = data;
    },
    setToken: (state, token) => {
        state.token = token;
    },
    setRefreshToken: (state, data) => {
        state.refreshToken = data;
    },
    setTokenExp: (state, data) => {
        state.tokenExp = data;
    },
    setUserId: (state, data) => {
        state.user.id = data;
    },
    setUserUsername: (state, data) => {
        state.user.username = data;
    },
    followUser: (state, data) => {
        state.user.followers.push(data);
    },
    unfollowUser: (state, data) => {
        state.user.followers = state.user.followers.filter(elem => {
            return elem.id != data.id;
        });
    },
    setUserInfos: (state, payload) => {
        state.user = {...payload};
    },
    setMessage: (state, message) => {
        state.message = message;
    },
    logout: (state) => {
        state.message = "";
        state.token = "";
        state.tokenExp = "";
        state.refreshToken = "";
        state.user = {};
        state.surveys = [];
        state.categories = [];
        state.questions = [];
        state.answers = [];
        state.choices = [];
        console.log(state);
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};