// french version

export default {
    // whole app
    appName: "Winexam", 

    // Home Component
    homeVisionLinkText: "Notre Vision",
    homeAboutLinkText: "A Propos de Nous",
    homeContactLinkText: "Contact",
    homeBtnLogin: "Connexion",
    homeBtnRegister: "Inscription",
    homeLangFrench: "Francais",
    homeLangEnglish: "English",
    homeLangGerman: "Deutsch",
    //
    homeHomeFirstText: "vous aide à apprendre en ligne à votre rythme",
    homeHomeSecondText: "Créez des questions, révisez et partagez les avec vos amis.",
    homeHomeThirdText: "Commencer",
    //
    homeVisionFirstText: "Nous vous accompagnons dans votre parcours",
    homeVisionSecondText: "Winexam est une application ayant pour but d'accompagner les eleves, etudiants et toute personnes desirant dans la preparation de son examen.",
    homeVisionThirdText: "Aprenez maintenant",
    //
    homeVision2FirstText: "Nous donnons la possibilite de travaillez ensemble",
    homeVision2SecondText: "Winexam par sa fonctionalite social vous permet de partager vos questionnaires pour que d'autre puissent y ajouter des questions et/ou juste les utiliser pour se preparer.",
    homeVision2ThirdText: "Allez-y",
    //
    homeAboutFirstText: "Parlant de nous",
    homeAboutSecondText: "Nous sommes deux developpeurs motives qui avons eu l'idee de creer une application web qui permettra aux etudiants et a toutes personnes desiderant apprendre de le faire facilement",
    //
    homeFeedbackFirstText: "Donnez nous votre experience",
    homeFeedbackSecondText: "Pour l'amelioration de l'application nous vous donnons la possibilite de nous noter et de nous envoyer vos feedback, n'hesitez sutout pas !",
    homeFeedbackThirdText: "Envoyer un feedback",
    //
    homeAlexName: "Alex Mboutchouang",
    homeAlexDescription: "Fondateur du projet et Developpeur FullStack de l'aplication",
    homeLnName: "Leonel Ngoya",
    homeLnDescription: "Developpeur FrontEnd charge de l'Inteface - Experience utilisateur de l'application",
    //
    homeContactFirstText: "Contactez nous",
    homeContactEmailplaceholdertText: "email",
    homeContactMessagePlaceholderText: "Laissez un message ...",
    homeContactSendBtnText: "Envoyer votre message",
    //
    homeCopyright: "Winexam tout droits reserves 2022",

    // Authentification Component
    LoginNewTitle: "Creer un compte",
    loginNewText: "Vous avez deja un compte ?",
    loginNewChangeText: " Connectez vous",
    loginNewBtnText: "Enregistrer",
    LoginLogTitle: "Se connecter",
    loginLogText: "Vous n'avez pas de compte ?",
    loginLogChangeText: "Creer un gratuitement",
    loginLogBtnText: "Connexion",
    loginFormId: "Nom d'utilisateur",
    loginFormEmail: "Adresse Mail",
    loginFormName: "Nom",
    loginFormFirstName: "Prenom",
    loginFormLevel: "Classe",
    LoginFormPassword: "Mot de passe",
    LoginFormPhone: "Numero de Telephone",

    // Footer Component
    footerConctactText: "Contacts",
    footerLinksText: "Liens utiles",
    footerLink1Text: "Police de confidentialite",
    footerLink2Text: "Condition d'utilisation",
    footerLink3Text: "FAQs",

    // Footer Component
    menuLeftHomeText: "Accueil",
    menuLeftDashboardText: "Tableau de bord",
    menuLeftMessageText: "Messages",
    menuLeftNotificationText: "Notifications",
    menuLeftEvolutionText: "Evolution",
    menuLeftSettingsText: "Parametres",
    menuLeftCommunityText: "Communaute",
    menuLeftLogoutText: "Se Deconnecter",
}