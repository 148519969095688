<template src="../templates/home.html"></template>

<script>
import Footer from "./commons/Footer.vue";


export default {
  title: "Winexam",
  name: "Home",
  metaInfo: {
    // Children can override the title.
    title: "Winexam",
    titleTemplate: "%s | Home",
    meta: [
      {"http-equiv": "Content-Type", content: "text/html; charset=utf-8"},
      {name: "viewport", content: "width=device-width, initial-scale=1"},
      {name: "description", content: "Winexam is an application designed to help scholars, students and anyone who wants to prepare for their exams."},
      {property: "og:title", content: "Home | Winexam"},
      {property: "og:site_name", content: ""},
      // The list of types is available here: http://ogp.me/#types
      {property: "og:type", content: "website"},
      // Should the the same as your canonical link, see below.
      {property: "og:url", content: "https://www.test.winexam.help"},
      {property: "og:image", content: "https://testapi.winexam.help/img/q6.png"},
      // Often the same as your meta description, but not always.
      {property: "og:description", content: "Winexam is an application designed to help scholars, students and anyone who wants to prepare for their exams."},

      // Twitter card
      {name: "twitter:card", content: "summary"},
      {name: "twitter:site", content: "https://www.test.winexam.help"},
      {name: "twitter:title", content: "Home | Winexam"},
      {name: "twitter:description", content: "I have things here on my site."},
      // Your twitter handle, if you have one.
      {name: "twitter:creator", content: "@zaker237"},
      {name: "twitter:image:src", content: "https://testapi.winexam.help/img/q6.png"},

      // Google / Schema.org markup:
      {itemprop: "name", content: "Home | Winexam"},
      {itemprop: "description", content: "Winexam is an application designed to help scholars, students and anyone who wants to prepare for their exams."},
      {itemprop: "image", content: "https://testapi.winexam.help/img/q6.png"}
    ]
  },
  components: {
    Footer,
  },
  data() {
    return {};
  },
  methods: {
    handleBurger() {
      const menu = document.querySelector(".menu-burger");
      const burger = document.querySelector(".navigation-burger");
      burger.addEventListener("click", () => {
        burger.classList.toggle("active");
        menu.classList.toggle("active");
      });
    },
    burger(lang = "en-gb") {
      const fr = document.querySelector("#frLangM");
      const en = document.querySelector("#enLangM");
      const gr = document.querySelector("#grLangM");

      if (lang == "fr") {
        en.classList.remove("active");
        gr.classList.remove("active");
        fr.classList.add("active");
      }

      if (lang == "en-gb") {
        fr.classList.remove("active");
        gr.classList.remove("active");
        en.classList.add("active");
      }
      if (lang == "de-de") {
        fr.classList.remove("active");
        en.classList.remove("active");
        gr.classList.add("active");
      }
      this.changeLang(lang);
    },
    changelangmenu(lang = "en-gb") {
      const fr = document.querySelector("#frLang");
      const en = document.querySelector("#enLang");
      const gr = document.querySelector("#grLang");

      if (lang == "fr") {
        en.classList.remove("active");
        gr.classList.remove("active");
        fr.classList.add("active");
      }

      if (lang == "en-gb") {
        fr.classList.remove("active");
        gr.classList.remove("active");
        en.classList.add("active");
      }

      if (lang == "de-de") {
        fr.classList.remove("active");
        en.classList.remove("active");
        gr.classList.add("active");
      }
      this.changeLang(lang);
    },
    changeLang(lang = "en-gb") {
      this.$i18n.locale = lang;
    },
  },
  created() {},
};
</script>

<style scoped>
@import "../assets/css/index.css";
</style>
