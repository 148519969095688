<template>
  <asside class="asside">
    <div class="asside-profile">
      <div>
        <h2>Hi, {{ this.user.name + " " + this.user.firstname }} !</h2>
        <h3>Student</h3>
      </div>
      <router-link :to="{ name: 'Profile' }"
        >
        <img class="asside-profile-img" src="../../assets/images/profile/profile.jpg" alt="">
      </router-link>
    </div>
  </asside>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AsideRight",
  data() {
    return {};
  },
  methods: {
    ...mapActions(["getUserInfos"]),
  },
  computed: {
    ...mapGetters(["user", "token"]),
  },
  async created() {
    await this.getUserInfos({
      token: this.token,
    });
  },
};
</script>

<style scoped>
@import "../../assets/css/index.css";
</style>
