<template src="../../templates/users/profile.html">
    
</template>

<script>
	import MenuLeft from "../commons/MenuLeft.vue";
	import AsideRight from "../commons/AsideRight.vue";

	export default {
		components: {
			MenuLeft,
			AsideRight
		},
		name: "Profile",
		data(){
			return {

			}
		},
		methods: {

		},
		computed: {

		},
		created(){

		}
	}
</script>

<style scoped>
	@import "../../assets/css/index.css";
</style>