// german version

export default {
    // whole app
    appName: "Winexam", 

    // Home Component
    homeVisionLinkText: "Unser Ziel",
    homeAboutLinkText: "Über Uns",
    homeContactLinkText: "KontaKt",
    homeBtnLogin: "Anmelden",
    homeBtnRegister: "Registrieren",
    homeLangFrench: "Francais",
    homeLangEnglish: "English",
    homeLangGerman: "Deutsch",
    //
    homeHomeFirstText: "hilft Ihnen, in Ihrem eigenen Tempo online zu lernen",
    homeHomeSecondText: "Erstellen Sie Fragen, wiederholen Sie sie und teilen Sie sie mit Ihren Freunden.",
    homeHomeThirdText: "Beginnen",
    //
    homeVisionFirstText: "Wir begleiten Sie auf Ihrem Lernweg",
    homeVisionSecondText: "Winexam ist eine Anwendung, die Schüler, Studenten und andere Personen, die sich auf eine Prüfung vorbereiten möchten, begleiten soll.",
    homeVisionThirdText: "Jetzt lernen",
    //
    homeVision2FirstText: "Wir geben Ihnen die Möglichkeit, zusammen zu arbeiten",
    homeVision2SecondText: "Winexam bietet eine soziale Funktionalität, die es Ihnen ermöglicht, Ihre Fragebögen mit anderen zu teilen, damit sie Fragen hinzufügen und/oder einfach nur zur Vorbereitung nutzen können.",
    homeVision2ThirdText: "Weiter",
    //
    homeAboutFirstText: "Über uns",
    homeAboutSecondText: "Wir sind zwei motivierte Entwickler, die die Idee hatten, eine webbasierte Anwendung zu entwickeln, die es Schülern und Studenten und allen, die lernen wollen, ermöglicht, dies auf einfache Weise zu tun.",
    //
    homeFeedbackFirstText: "Teilen Sie uns Ihre Erfahrungen mit",
    homeFeedbackSecondText: "Um die Anwendung zu verbessern, geben wir Ihnen die Möglichkeit, uns zu bewerten und uns Ihr Feedback zu schicken, bitte zögern Sie nicht!",
    homeFeedbackThirdText: "Feedback senden",
    //
    homeAlexName: "Alex Mboutchouang",
    homeAlexDescription: "Gründer des Projekts und FullStack-Entwickler der Anwendung",
    homeLnName: "Leonel Ngoya",
    homeLnDescription: "FrontEnd-Entwickler, der für die Benutzeroberfläche zuständig ist",
    //
    homeContactFirstText: "Kontaktieren Sie uns",
    homeContactEmailplaceholdertText: "E-Mail",
    homeContactMessagePlaceholderText: "Hinterlassen Sie eine Nachricht ...",
    homeContactSendBtnText: "Nachricht senden",
    //
    homeCopyright: "Winexam alle Rechte vorbehalten 2022",

    // Authentification Component
    LoginNewTitle: "Ein Konto erstellen",
    loginNewText: "Haben Sie bereits ein Konto ?",
    loginNewChangeText: " Anmelden",
    loginNewBtnText: "Registrieren",
    LoginLogTitle: "Anmelden",
    loginLogText: "Sie haben kein Konto ?",
    loginLogChangeText: " Erstellen Sie ein",
    loginLogBtnText: "Anmelden",
    loginFormId: "Benutzername",
    loginFormEmail: "Email Adresse",
    loginFormName: "Nachname",
    loginFormFirstName: "Vorname",
    loginFormLevel: "Stufe",
    LoginFormPassword: "Passwort",
    LoginFormPhone: "Telefonnummer",

    // Footer Component
    footerConctactText: "Kontakte",
    footerLinksText: "nützliche Links",
    footerLink1Text: "Datenschutzbestimmungen",
    footerLink2Text: "Bedingungen für die Nutzung",
    footerLink3Text: "FAQs",

    // Footer Component
    menuLeftHomeText: "Startseite",
    menuLeftDashboardText: "Dashboard",
    menuLeftMessageText: "Messages",
    menuLeftNotificationText: "Benachrichtigungen",
    menuLeftEvolutionText: "Fortschritt",
    menuLeftSettingsText: "Einstellungen",
    menuLeftCommunityText: "Gemeinschaft",
    menuLeftLogoutText: "Abmelden",
}