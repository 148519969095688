import axios from "axios";
import {
    QUESTIONS_ENDPOINT,
    CHOICES_ENDPOINT,
    ANSWER_ENDPOINT
} from "../settings";
import jwtInterceptor from "../../utils/jwtInterceptor";

var state = {
    questions: [],
    questionPage: 1,
    totalQuestionPage: 1
}

var getters = {
    questions: (state) => state.questions,
    questionsPage: (state) => state.questionsPage,
    totalQuestionPage: (state) => state.totalQuestionPage,
}

var actions = {
    async getQuestions({ commit }, data){
        const body = {
            survey: data.survey
        }
        try {
            const response = await axios.get(
                QUESTIONS_ENDPOINT,
                {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    },
                    params: {
                        ...body
                    }
                }
            );
            if(response){
                commit("setQuestions", response.data);
            }
        }catch(error){
            console.log(error);
        }
    },

    async addQuestion({ commit }, data){
        const body = {
            survey: data.question.survey,
            text: data.question.text,
            explanation: data.question.explanation,
            is_multi: data.question.is_multi,
            choices: data.question.choices
        };
        
        try{
           const response = await jwtInterceptor.post(
               QUESTIONS_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("newQuestion", response.data);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async updateQuestion({ commit }, data){
        const body = {
            "id": data.category.id,
            "name": data.category.name,
            "description": data.category.description
        };
        
        try{
           const response = await jwtInterceptor.put(
               QUESTIONS_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("updateCategory", data.category);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async deleteQuestion({ commit }, data){
        const body = {
            "id": data.category.id
        };
        
        try{
           const response = await jwtInterceptor.delete(
               QUESTIONS_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("deleteCategory", data.category.id);
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async getQuestionChoices({ commit }, data){
        const body = {
            "question": data.question.id
        }
        
        try{
           const response = await axios.get(
               CHOICES_ENDPOINT,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    },
                    params: {
                        ...body
                    }
                }
            );
            if(response){
                commit("setQuestionChoice", {question: data.question.id, data: response.data});
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async addChoice({ commit }, data){
        const body = {
            text: data.choice.text,
            is_answer: data.choice.is_answer
        };
        
        try{
           const response = await axios.post(
               CHOICES_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("addChoice", {question: data.question.id, data: response.data});
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async updateChoice({ commit }, data){
        const body = {
            "choice": data.choice.id,
            "text": data.choice.text,
            "is_answer": data.choice.is_answer
        };
        
        try{
           const response = await axios.put(
               CHOICES_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("updateChoice", {question: data.question.id, data: data.choice});
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async deleteChoice({ commit }, data){
        const body = {
            "choice": data.choice.id
        };
        
        try{
           const response = await axios.delete(
               CHOICES_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                commit("deleteChoice", {question: data.question.id, data: data.choice});
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },

    async addAnswerToQuestion(_, data){
        const body = {
            question: data.question,
            answer: data.answer
        };
        
        try{
           const response = await axios.post(
               ANSWER_ENDPOINT,
               body,
               {
                    headers: {
                        Authorization: `Bearer ${data.token}`
                    }
                }
            );
            if(response.data.ok){
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.error(error);
            return false;
        }
    },
}

var mutations = {
    setQuestions: (state, payload) => {
        /*eslint no-unused-vars: "error"*/
        state.questions = [].concat(payload.sort((a, b) => 0.5 - Math.random()));
        /*eslint no-unused-vars: 0*/
    },
    newQuestion: (state, payload) => {
        state.questions.push(payload);
    },
    updateQuestion: (state, payload) => {
        const idx = state.questions.find(
            elem => elem.id == payload.id
        );
        state.questions[idx] = payload;
    },
    deleteQuestion: (state, payload) => {
        state.questions = state.questions.filter(
            elem => elem.id != payload
        );
    },
    setQuestionPage: (state, page) => {
        state.questionPage = page;
    },
    setTotalQuestionPage: (state, page) => {
        state.totalQuestionPage = page;
    },
    setQuestionChoice: (state, payload) => {
        const index = state.questions.find(
            elem => elem.id == payload.question
        );
        state.questions[index].choices = payload.data;
    },
    updateChoice: (state, payload) => {
        const index = state.questions.find(
            elem => elem.id = payload.question
        );
        const choice_index = state.questions[index].choices.find(
            elem => elem.id == payload.data.id
        );
        state.questions[index].choices[choice_index] = {...payload.data};
    },
    deleteChoice: (state, payload) => {
        const index = state.questions.find(
            elem => elem.id = payload.question
        );
        state.questions[index].choices = state.questions[index].choices.filter(
            elem => elem.id != payload.data.id
        );
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}