<template src="../templates/error-404.html">
    
</template>

<script>
export default {
	title: "Oups Not Found"
}
</script>

<style scoped>
@import "../assets/css/index.css";
</style>
